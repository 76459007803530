/*Custom Style by Obaidullah :)*/

i.fas {
  color: inherit;
}

.aside {
  background-color: #fff;
}

.brand-dark .brand {
  height: 150px;
  justify-content: center;
  border-radius: 0 50px 0 0;
}

.brand-logo [alt="logo"] {
  width: 125px;
}

#kt_header_mobile img {
  width: 160px;
  margin-top: 9px;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon svg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  display: flex;
  align-items: center;
  color: #4e4e4e;
  height: 100%;
}

.w130 {
  width: 130px;
}

.w230 {
  width: 230px;
}

.w175 {
  width: 175px;
}

.progress {
  background-color: #dfdfdf !important;
}

[role="table"] [role="cell"] {
  vertical-align: middle;
}

.customMainCard .card-title {
  font-size: 18px !important;
  font-weight: 600;
}

.customCard {
  background: white;
  box-shadow: none !important;
  border-radius: 5px;
  border: 1px solid #E0E8EB !important;
}

.customCard .customIcon {
  width: 35px;
}

.customCard .totalText {
  font-size: 24px !important;
  font-weight: 500 !important;
  color: #414141;
  font-family: 'Poppins';
}

.customCard .total_text_active {
  font-size: 36px !important;
  font-weight: bolder !important;
  color: white !important;
}

.card_title_active {
  font-size: 1.1rem;
  color: #ffffff !important;
  display: flex;
  align-items: flex-end;
  height: 40px !important;
  line-height: 1.2;
}

.customCard .cardTitle {
  font-size: 1.1rem;
  color: #797373 !important;
  /* display: flex; */
  align-items: flex-end;
  /* height: 40px !important; */
  line-height: 1.2;
}

.summary_card_spacer {
  padding: 1rem 1.25rem !important
}

.customCard .customIcon {
  width: 35px;
}

.customCard .totalText {
  font-size: 24px !important;
  font-weight: 500 !important;
  color: #414141;
  font-family: 'Poppins';
}

.customMainCard {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.customExportBtn {
  background-color: #ebfdfa !important;
  color: #000 !important;
  font-weight: 600;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 9px;
  border: 0;
}

.customSendBtn {
  font-weight: 600;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 9px;
  border: 0;
}

.bg-success {
  background-color: #76d880 !important;
}

.customNav .nav-link {
  padding: 0.5rem 1.5rem;
  border-bottom: 5px solid transparent;
}

.customNav .nav-link.active {
  color: #888484;
  border-bottom: 5px solid #eb008a;
}

.customPagination button i {
  font-size: 1rem !important;
}

.count_font {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.customDivider {
  margin: 0;
  padding: 0;
  height: 0;
  display: block;
  border-bottom: 1px solid #ebedf3;
}

.custom_dropdown_div {
  height: 70px;
  width: 570px;
  background-color: white;
  margin-top: 10px;
  position: absolute;
  top: 35px;
  padding-top: 15px;
  z-index: 1000;
  left: -177px;
  border: 1px solid #c2c1c1;
  border-radius: 6px;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
}
.filled_image_style{
  filter: brightness(0) invert(1);
}
.custom_dropdown_main_dashboard {
  /* height: 70px; */
  width: 178px;
  background-color: white;
  margin-top: 10px;
  position: absolute;
  right: 0px;
  top: 35px;
  padding-top: 15px;
  z-index: 9999;
  border: 1px solid #c2c1c1;
  border-radius: 6px;
  box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
}

.searchInput {
  position: relative;
}

.searchInput svg {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
}

.searchInput .xIcon {
  right: 15px;
  width: 11px;
}

/* Videos Centre Css */

.video-container .videoImage {
  position: relative;
  background-color: #dfdfdf;
}

.video-container .videoImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-container .videoImage .videoLength {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: black;
  color: white;
  padding: 0px 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
}

.video-container .videoTitle {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.3;
  color: #444;
  height: 34px;
  margin: 8px 0 0;
  display: block;
  -webkit-line-clamp: 2;
  display: flex;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}

.uppy-Dashboard-Item-previewInnerWrap {
  background-color: #dfdfdf !important;
  background-size: cover;
  background-repeat: no-repeat;
}

.uppy-Dashboard-Item-preview svg rect {
  fill: transparent;
}

/* Dashboard Count */

.aside-menu .menu-nav>.menu-item>.menu-link .menu-arrow::before {
  content: unset;
}

.aside-menu .menu-nav>.menu-item>.menu-link .menu-arrow {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
}

.aside-menu .menu-nav>.menu-item>.menu-link:hover .menu-arrow,
.aside-menu .menu-nav>.menu-item>.menu-link.active .menu-arrow {
  color: #ffffff !important;
}

.errorText {
  font-size: small !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.applyButton {
  background-color: #13B9A0 !important;
  border-color: #13B9A0 !important;
}

.analytic-date-range .timeSelectContainer {
  display: none !important;
}

.custom-daterange {
  padding: 7px 11px 7px !important;
}

.custom-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3f4254;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
  box-shadow: none;
}

.dropdown-toggle.nav-link:before,
.dropdown-toggle.nav-link:after,
.dropdown-toggle.btn:before,
.dropdown-toggle.btn:after {
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  text-align: center;
  display: none !important;
  border: 0;
  font-size: 0.6rem;
  line-height: 0;
  vertical-align: middle;
  position: relative;
}

.custom-dropDown {
  padding: 0px !important;
}

.btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-primary:focus:not(.btn-text),
.btn.btn-primary.focus:not(.btn-text) {
  color: #FFFFFF;
  background-color: #13B9A0;
  border-color: #13B9A0;
}

.font_family_poppins {
  font-family: 'Poppins';
}

.custom-dropDown .btn.btn-primary {
  background-color: transparent;
  color: #13B9A0;
  border: none !important;
  padding: 4px !important;
}

.floating-label {
  position: absolute;
  pointer-events: none;
  left: 2rem !important;
  top: 11% !important;
}

.float-above {
  /* font-size: 0.9rem !important; */
  color: #007bff !important;
  position: absolute !important;
  top: -10px !important;
}

.custom-dropdown-btn-color {
  color: #787875;
}

/* .custom_view {
  display: flex !important;
  ;
} */

@media (max-width: 991.98px) {
  .custom_view {
    display: block !important;
    ;
  }

  .sm-mt-15 {
    margin-top: 10px;
    color: #787875;
  }

  .custom_dropdown_div {
    height: 205px;
    width: 317px;
    background-color: white;
    margin-top: 10px;
    position: absolute;
    top: 35px;
    padding-top: 15px;
    z-index: 1000;
    left: 15px;
    border: 1px solid #c2c1c1;
    border-radius: 6px;
    box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
  }
}